import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import BoardMain from '@board/components/board/BoardMain.vue'
import SubContentTemplate from '@appbase/templates/SubContentTemplate.vue'

@Component({
  components: {
    BoardMain,
    SubContentTemplate
  },
  computed: {
    
  },
})
export default class BoardPropxyView extends Vue {
  
}
